import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './style.css';

export default function About() {
    return (
        <div>
            <Container>
                <div className="pt-4 pb-3">
                    <div className="aboutTitle">
                        <h1> Sobre </h1>
                    </div>
                    <Row>
                        <Col sm="12" xs="12">
                            <p className="lead">
                                O SAMUCA ( Serviço de Atendimento à Mulher à Criança e ao Adolescente)
                                nasceu da necessidade premente de uma parte da população localizada no
                                bairro Jardim Triunfo na cidade Pedreira SP. Integrava esta população,
                                algumas famílias que viviam num único prédio em péssimas condições de habitação,
                                cuja marca mais evidente eram a pobreza e a falta de perspectiva de vida.
                                Sensibilizados por este quadro, O Sr. Hamilton Bernardes Junior, prefeito
                                da época, e sua esposa Maria Luíza M. Bernardes, incubaram por três anos
                                a ideia de criar uma instituição que acolhesse estas famílias e lhes devolvesse
                                a dignidade, a auto-estima, a possibilidade de trabalho e a certeza de que
                                poderiam modificar radicalmente suas vidas.
                            </p>
                        </Col>
                        <Col sm="12" xs="12">
                            <p className="lead">
                                Hoje, com 25 anos de vida, o Samuca continua sendo um referencial importante
                                dentro do município atendendo crianças e adolescentes de 6 à 14 anos no
                                contra turno escolar, além de desenvolver trabalhos de serviços de
                                convivência e fortalecimento de vinculo focado na criança e em sua família.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
            <br/>
        </div>
    )
}