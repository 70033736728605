import React from 'react';

import './App.css';

import Routes  from './routes';

import "toastr/build/toastr.min.js";

import 'bootstrap/dist/css/bootstrap.min.css';

import "toastr/build/toastr.css";

import { Provider } from 'react-redux';

import 'react-app-polyfill/stable';

import store from './Store.js';


export default function App() { 
    return (
    <Provider store={store}>
        <Routes /> 
    </Provider>)

}