import Button from '@restart/ui/esm/Button';
import React from 'react';

import { successMessage, errorMessage } from "../../components/toastr";
import { Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import SuperiorMenu from '../../components/superiorMenu/';
import ImageGallery from 'react-image-gallery';
import Footer from '../../components/footer';
import ImageService from '../../app/services/ImageService';
import EventService from '../../app/services/EventService';
import Select from 'react-select';

import './style.css';

export default function PhotoGallery() {

    const imageService = new ImageService();

    const eventService = new EventService();

    const [events, setEvents] = useState([]);
    const [eventId, setEventId] = useState();

    const [youtubeLink, setYoutubeLink] = useState();
    const [facebookLink, setFacebookLink] = useState();

    const [imagesValue, setImagesValue] = useState([]);
    const [allImages, setAllImages] = useState([]);

    const [isYoutube, setIsYoutube] = useState(false);
    const [isFacebook, setIsFacebook] = useState(false);

    useEffect(() => {
        new EventService().getEvent().then(response => {
            setEvents(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const handleChange = e => {
        setEventId(e.value);
    }

    const getImagesById = async e => {
        e.preventDefault();

        const images = [];

        var event = await eventService.getEventLinksById(eventId);

        setYoutubeLink(event.data.youtubeLink);
        setIsYoutube(event.data.youtubeLink !== null);

        setFacebookLink(event.data.facebookLink);
        setIsFacebook(event.data.facebookLink !== null);

        await imageService.getImage(eventId)
            .then(response => {
                setAllImages([]);
                setAllImages(response.data);

                for (var i = 0; i < response.data.length; i++) {
                    const original = "data:image/jpeg;base64, " + response.data[i].imageData;
                    const thumbnail = original;
                    const objImage = { original: original, thumbnail: thumbnail };
                    images.push(objImage);
                }
                successMessage("");
            })
            .catch(err => {
                if (err.response === undefined)
                    errorMessage("Servidor de busca com erro, tente novamente mais tarde");
                else if (err.response.status === 404)
                    errorMessage("Não existem fotos para o evento selecionado");
                else
                    errorMessage("Selecione um Evento");
            });


        setImagesValue(images);
    }

    return (
        <div>
            <SuperiorMenu></SuperiorMenu>
            <Container>
                <Row>
                    <div className="input-group ">
                        <Select options={events}
                            id="typeahead-focus"
                            type="text"
                            onChange={handleChange}
                            className="select-input form-control"
                            placeholder="selecione um evento..."
                        />

                        <div className="input-group-append">
                            <Button className="btn btn-primary" type="button" onClick={getImagesById}>Buscar</Button>
                        </div>
                    </div>
                </Row>
                <br /> <br /> <br />
                <div>
                    <ImageGallery items={imagesValue} />
                    {isFacebook && <a href="https://facebook.com" className="activeLink">Para ver o album completo clique aqui</a>}
                    <br/>
                    {isYoutube && <a href="https://youtube.com" className="activeLink">Para acessar videos desse evento clique aqui</a>}
                </div>
            </Container >
            <br />
            <Footer />
        </div >
    )
}