import React from 'react';
import { useState } from "react";

import { Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SuperiorMenu from '../../components/superiorMenu';
import Footer from '../../components/footer';

import { successMessage, errorMessage } from "../../components/toastr";
import PdfService from '../../app/services/PdfService';

import './style.css';

export default function TranparencyChannel() {

    const pdfService = new PdfService();

    const [searchDate, setSearchDate] = useState(new Date());
    const [isSaving, setIsSaving] = useState("");
    const [pdfType, setPdfType] = useState("");
    const [pdfValues, setPdfValues] = useState([]);

    const getAllPdf = async e => {

        e.preventDefault();
        setIsSaving(true);

        const transparencyYear = searchDate.split("-")[0];
        const transparencyMonth = searchDate.split("-")[1];

        await pdfService.filter(transparencyYear, transparencyMonth, pdfType)
            .then(response => {
                setIsSaving(false);
                setPdfValues(response.data);
                successMessage(response.data);
            })
            .catch(err => {
                console.log(err.response);
                setPdfValues([]);
                if (err.response === undefined)
                    errorMessage("Servidor de busca com erro, tente novamente mais tarde")
                else
                    errorMessage(err.response.data)
                setIsSaving(false);
            });
    }

    return (
        <div>
            <SuperiorMenu></SuperiorMenu>
            <Container>
                <Tabs defaultActiveKey="transparencia" id="uncontrolled-tab-example" className="mb-3" onClick={() => setPdfValues([])}>
                    <Tab eventKey="transparencia" title="transparencia" className="tabTransparencyChannel">
                        <form onSubmit={getAllPdf}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="searchDate">
                                        <Form.Control
                                            type="month"
                                            name="searchDate"
                                            placeholder="searchDate"
                                            onChange={(e) => setSearchDate(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Button type="submit" className="Botao-Entrar" variant="primary" onClick={() => setPdfType(1)}>
                                        {isSaving ? <><Spinner animation="border" variant="light" size="sm" /> Buscando ...</> : 'Buscar'}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        <br />
                        <Row>
                            <Container>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Data do Arquivo</th>
                                            <th>Pdf</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pdfValues.map(value => (
                                                <tr>
                                                    <td>{value.pdfDate}</td>
                                                    <td><a download="pdfTitle.pdf" href={"data:application/pdf;base64," + value.pdfData}>{value.pdfName}</a></td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </Table>
                            </Container>
                        </Row>
                    </Tab>

                    <Tab eventKey="salario" title="salario" className="tabTransparencyChannel">
                        <form onSubmit={getAllPdf}>
                            <Row>
                                <Col>
                                    <Form.Group controlId="searchDate">
                                        <Form.Control
                                            type="month"
                                            name="searchDate"
                                            placeholder="searchDate"
                                            onChange={(e) => setSearchDate(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button type="submit" className="Botao-Entrar" variant="primary" onClick={() => setPdfType(2)}>
                                        {isSaving ? <><Spinner animation="border" variant="light" size="sm" /> Buscando ...</> : 'Buscar'}
                                    </Button>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Container>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Data do Arquivo</th>
                                                <th>Pdf</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pdfValues.map(value => (
                                                    <tr>
                                                        <td>{value.pdfDate}</td>
                                                        <td><a download="pdfTitle.pdf" href={"data:application/pdf;base64," + value.pdfData}>{value.pdfName}</a></td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </Table>
                                </Container>
                            </Row>
                        </form>
                    </Tab>
                </Tabs>
            </Container>
            <Footer />

        </div>
    )
}