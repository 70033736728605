import ApiService from "../apiService";

export default class ImageService extends ApiService {
    constructor() {
        super("/image");
    }

    postSaveImage(image) {
        return this.post("/",image);
    }

    getImage(eventId) {
        return this.get(`?eventId=${eventId}`);
    }

}