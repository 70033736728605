import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';

import './style.css';

export default function Footer() {
    return (
        <div>
            <section >
                <Container>
                    <br /> <hr /><br />
                    <Row className="footerValues">
                        <Col lg="6">
                            <h4 className="text-primary"> Endereço </h4>
                            <h6>Rua Antonio Provatti, 301 
                            Bairro Jd. Triunfo - Pedreira </h6>
                            <div>
                                <h6>Secretária e Administração: (19) 99932-2765 </h6>
                                <h6>Coordenação e Técnicas: (19) 99650-8850 </h6>
                            </div>
                        </Col>

                        <Col lg="6">
                            <SocialIcon url="https://www.facebook.com/samuca.pedreira" className="socialIconFooter"/>&nbsp;
                            <SocialIcon url="https://api.whatsapp.com/send?phone=5519996325849" className="socialIconFooter"/>&nbsp;
                            <SocialIcon url="https://www.instagram.com/samucapedreira/" className="socialIconFooter"/>&nbsp;
                            <SocialIcon url="https://www.youtube.com/channel/UCxiXAqnF_DYK6OME8e1turQ"className="socialIconFooter"/>&nbsp;
                        </Col>
                    </Row>
                    <br />
                    <hr />
                    <div className="copyright">
                        <h6>Copyright © 2021 | CMS</h6>
                    </div>
                </Container>
                <br />
            </section>
        </div>
    )
}