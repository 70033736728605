import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import imgBancoBrasil from '../images/bancoDoBrasil.png';

import './style.css';

export default function MoreInfo() {
    return (
        <div>
            <Container>
                <Row>
                    <Col md="6" lg="6">
                        <h2>Seja nosso Colaborador</h2>
                        <hr />
                        <div class="collaborators">
                            <p>AG: 2427-9</p>
                            <p>Conta Corrente: 36.329-4</p>
                            <img src={imgBancoBrasil} alt="Banco do Brasil" />
                        </div>
                    </Col>
                    <Col md="6" lg="6">
                        <h2> Eventos </h2>
                        <hr />
                        <div className="myCalendar">
                            <FullCalendar
                                locale={ptbrLocale}
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <br />
        </div >
    )
}