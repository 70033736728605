import React from "react";

import { Image, Nav, Navbar } from "react-bootstrap";
import logo from '../images/logoSamuca.png';

import './style.css';

export default function superiorMenu() {
    return (
        <div>
            <Navbar bg="primary" expand="lg" className="menuBar">
                    <Navbar.Brand href="/home"><Image src={logo} className="inicialLogo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav >
                            <Nav.Link href="/photo-gallery" className="menuLink">Galeria de Fotos</Nav.Link>
                            <Nav.Link href="/transparency-channel" className="menuLink">Canal da Transparencia</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
            <br />
        </div>
    )
}