import React from 'react';

import SuperiorMenu from '../../components/superiorMenu';
import Footer from '../../components/footer';

import './style.css';

export default function NotFound() {
    return (
        <div>
            <SuperiorMenu></SuperiorMenu>
            <div className="notFoundText">
                <br/>
                <h2>Página não encontrada !</h2>
            </div>
            <Footer></Footer>
        </div>
    )
}