import ApiService from "../apiService";

export default class UploadFilesService extends ApiService {
    constructor() {
        super("/pdf");
    }

    postSavePdf(pdf) {
        return this.post("/", pdf);
    }

    getAll() {
        return this.get("/");
    }

    getById(id) {
        return this.get(`/id?id=${id}`);
    }

    filter(year, month, type) {
        return this.get(`/filter?year=${year}&month=${month}&type=${type}`);
    }
}