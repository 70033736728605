import ApiService from "../apiService";

export default class EventService extends ApiService {
    constructor() {
        super("/event");
    }

    postSaveEvent(event) {
        return this.post("/", event);
    }

    getEvent() {
        return this.get("/");
    }
    
    getEventLinksById(eventId) {
        return this.get(`/getEventLinksById?eventId=${eventId}`);
    }
}