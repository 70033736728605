import ApiService from "../apiService";

export default class SendEmailService extends ApiService {
    constructor() {
        super("/java-mail");
    }

    postSendEmail(mail) {
        return this.post("/", mail);
    }
}