import React from 'react';
import { useState } from "react";

import { Col, Container, Form, Row } from 'react-bootstrap';
import SendEmailService from '../../app/services/SendEmailService';
import { successMessage, errorMessage } from "../../components/toastr";
import './styles.css';

export default function Contact() {

    const sendEmailService = new SendEmailService();
    
    const [userName, setUserName] = useState("");
    const [cel, setCel] = useState("");
    const [mail, setMail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = e => {

        e.preventDefault();


        sendEmailService.postSendEmail({ "name": userName, "cel": cel, "mail": mail, "subject": subject, "message": message })

            .then(response => {
                console.log(response.data);
                successMessage("Mensagem enviada com sucesso!");
            })
            .catch(err => {
                if (err.response === undefined)
                    errorMessage("Servidor está passando por instabilidade\n Tente novamente mais tarde, ou entre em contato por nossas redes socias !")
                else
                    errorMessage("Erro ao enviar mensagem: " + err)
            });
    }

    return (
        <div>
            <Container>
                <Row>
                    <h1 className="contactTitle"> Entre em contato </h1>
                </Row>
                <Row >
                    <Col lg="6">
                        <Form.Group className="contactForm">
                            <Form.Control placeholder="Nome" 
                                type="text" 
                                id="contactName" 
                                value={userName} 
                                onChange={e => setUserName(e.target.value)}
                                />
                        </Form.Group>
                    </Col>
                    <Col lg="6">
                        <Form.Group className="contactForm">
                            <Form.Control placeholder="Telefone / Celular" 
                                type="tel"
                                id="contactCel" 
                                value={cel}
                                onChange={e => setCel(e.target.value)}
                                />
                        </Form.Group>
                    </Col>
                    <Col lg="6">
                        <Form.Group className="contactForm">
                            <Form.Control placeholder="Email"
                                 type="email"
                                 id="contactMail"
                                 value={mail}
                                 onChange={e => setMail(e.target.value)}
                                 />
                        </Form.Group>
                    </Col>
                    <Col lg="6">
                        <Form.Group className="contactForm">
                            <Form.Control placeholder="Assunto"
                                type="text"
                                id="contactSubject"
                                value={subject} 
                                onChange={e => setSubject(e.target.value)}
                                />
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Form.Group className="contactForm">
                            <Form.Control placeholder="Mensagem..." 
                                type="textarea" 
                                rows="5"
                                 id="contactMessage" 
                                 value={message}
                                 onChange={e => setMessage(e.target.value)}
                                 />
                        </Form.Group>
                    </Col>
                </Row>
                <button type="button" className="btn btn-primary" onClick={sendEmail}> Enviar </button>
            </Container>
            <br />
        </div >
    )
}