import React, { useEffect, useState } from 'react';

import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { successMessage, errorMessage } from "../../components/toastr";
import Footer from '../../components/footer';
import SuperiorMenu from '../../components/superiorMenu';
import EventService from '../../app/services/EventService';
import ImageService from '../../app/services/ImageService';
import UploadFilesService from '../../app/services/PdfService';

import Select from 'react-select';

import './style.css';

export default function UploadImage() {

    const eventService = new EventService();
    const imageService = new ImageService();
    const uploadFilesService = new UploadFilesService();

    const [eventName, setEventName] = useState();
    const [eventId, setEventId] = useState();
    const [eventDate, setEventDate] = useState(new Date());
    const [fileEventdate, setFileEventDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [imageFile, setImageFile] = useState();

    const [pdfType, setPdfType] = useState();
    const [pdf, setPdf] = useState();
    const [pdfFile, setPdfFile] = useState();

    const [facebookLink, setFacebookLink] = useState();
    const [youtubeLink, setYoutubeLink] = useState();


    useEffect(() => {
        setPdfType(1);
        new EventService().getEvent().then(response => {
            setEvents(response.data);
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const handleChange = e => {
        setEventId(e.value);
    }

    const handleImageChange = e => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImageFile(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    const handlePdfChange = e => {
        const fileToLoad = e.target.files[0];
        setPdf(fileToLoad.name);

        var fileReader = new FileReader();
        var base64File;
        fileReader.onload = function (event) {
            base64File = event.target.result;
            setPdfFile(base64File);
        };

        fileReader.readAsDataURL(fileToLoad);
    }

    const eventRegister = e => {

        e.preventDefault();
        const year = eventDate.split('-')[0];
        const month = eventDate.split('-')[1];

        eventService.postSaveEvent({ "eventId": 9999, "eventName": eventName, "month": month, "year": year, "facebookLink": facebookLink, "youtubeLink" :youtubeLink })
            .then(response => {
                console.log(response.data);
                successMessage("Evento salvo com sucesso");
            })
            .catch(err => {
                if (err.response === undefined)
                    errorMessage("Servidor de busca com erro, tente novamente mais tarde")
                else
                    errorMessage("Erro ao salver o evento: " + err)
            });
    }

    const saveImage = e => {
        const imageId = 99999;
        const imageData = imageFile.split(",")[1];;

        imageService.postSaveImage({ "imageId": imageId, "imageData": imageData, "eventId": eventId })
            .then(response => {
                console.log(response.data);
                successMessage("Imagem salva com sucesso !")
            })
            .catch(err => {
                if (err.response === undefined)
                    errorMessage("Servidor de busca com erro, tente novamente mais tarde")
                else
                    errorMessage("Erro ao salver o imagem: " + err)
            })
    }

    const savePdf = e => {
        const pdfId = 99999;
        const year = fileEventdate.split("-")[0];
        const month = fileEventdate.split("-")[1];
        const pdfData = pdfFile.split(",")[1];;

        uploadFilesService.postSavePdf({ "id": pdfId, "name": pdf, "data": pdfData, "month": month, "year": year, "type": pdfType })
            .then(response => {
                console.log(response.data);
                successMessage("Pdf salvo com sucesso !")
            })
            .catch(err => {
                if (err.response === undefined)
                    errorMessage("Servidor de busca com erro, tente novamente mais tarde")
                else
                    errorMessage("Erro ao salver o pdf: " + err)
            })
    }

    return (
        <div>
            <SuperiorMenu />
            <Container>
                <Tabs defaultActiveKey="eventos" id="uncontrolled-tab-example">
                    <Tab eventKey="eventos" title="eventos" className="tabUploadImage">
                        <br />
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formeventRegister">

                                    <Form.Control type="text" placeholder="Registrar novo Evento" value={eventName}
                                        onChange={(e) => setEventName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="duedate">
                                    <Form.Control
                                        type="month"
                                        name="eventdate"
                                        placeholder="Due date"
                                        value={eventDate}
                                        onChange={(e) => setEventDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type="text" placeholder="Link do album do evento no facebook " value={facebookLink} onChange={(e) => setFacebookLink(e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Control type="text" placeholder="Link do video do youtube" value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={eventRegister}> Salvar </Button>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="fotos" title="fotos" className="tabUploadImage">
                        <br />
                        <Row>
                            <Col>
                                <Form.Control type="file" accept="image/png, image/jpeg" onChange={handleImageChange} />
                            </Col>
                            <Col>
                                <Select options={events}
                                    id="typeahead-focus"
                                    type="text"
                                    onChange={handleChange}
                                    className="select-input form-control"
                                    placeholder="selecione um evento..."
                                />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={saveImage}> Salvar </Button>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="arquivos" title="arquivos" className="tabUploadImage">
                        <br />
                        <Row>
                            <Col>
                                <Form.Control type="file" accept="application/pdf" onChange={handlePdfChange} />
                            </Col>
                            <Col>
                                <Form.Select aria-label="Default select example" onChange={(e) => setPdfType(e.target.value)}>
                                    <option value="1">Transparência</option>
                                    <option value="2">Salário</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Group controlId="duedate">
                                    <Form.Control
                                        type="month"
                                        name="duedate"
                                        placeholder="Due date"
                                        value={fileEventdate}
                                        onChange={(e) => setFileEventDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={savePdf}> Salvar </Button>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
            <br />
            <Footer />
        </div>
    )
}