import React from 'react';
import { Container } from 'react-bootstrap';
import forefront from '../images/fachada.jpeg'

export default function ForeFrontImage() {
    return (
        <div>
            <Container>
                <img src={forefront} alt="samuca" className="foreFrontImage" />
            </Container>
        </div>
    )
}