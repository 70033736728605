import toastr from "toastr";

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

export function showMenssage(titulo, message, tipo) {
    toastr[tipo](message, titulo)
}

export function errorMessage(message) {
    showMenssage("Erro", message, "error")
}

export function successMessage(message) {
    showMenssage("Sucesso", message, "success")
}

export function alertMessage(message) {
    showMenssage("Alerta", message, "warning")
}
