import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/home';
import PhotoGallery from './pages/photoGallery';
import TranparencyChannel from './pages/transparencyChannel';
import UploadImage from './pages/uploadImage';

import NotFound from './pages/notFound';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/"><Redirect to="/home" /></Route>
                <Route path="/home" component={Home} />
                <Route path="/photo-gallery" component={PhotoGallery} />
                <Route path="/transparency-channel" component={TranparencyChannel} />
                <Route path="/upload-image" component={UploadImage} />
                <Route path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
}