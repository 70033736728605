import React from 'react';

import SuperiorMenu from '../../components/superiorMenu';
import About from '../../components/about';
import Contact from '../../components/contact';
import ForeFrontImage from '../../components/foreFront';
import MoreInfo from '../../components/moreInfo';
import Footer from '../../components/footer';

import './style.css';

export default function NotFound() {
    return (
        <div>

            <SuperiorMenu></SuperiorMenu>

            <ForeFrontImage></ForeFrontImage>

            <About></About>

            <Contact></Contact>

            <MoreInfo></MoreInfo>

            <Footer></Footer>

        </div>
    )
}